import { put, takeLatest, call, select } from 'redux-saga/effects';
import uuid from 'uuid/v4';

import { StoreState } from 'app/state/rootReducer';
import Storage from 'app/shared/utils/Storage';
import { emitError, emitSuccess } from 'app/state/status/actions';

import {
  getBySlug,
  getCurrentUser,
  getSpecialties,
  getPartner,
  acceptTerms,
  signUp,
  ProfilePayload,
} from 'app/services/api/profile';
import { bulkUpdate } from 'app/services/api/insurances';
import { setStatusForm } from 'shared/utils/validation';
import { handleSubmitLogin } from 'app/state/auth/actions';
import { notificationAdd } from '../notification/actions';
import {
  fetchFailure,
  fetchSuccess,
  fetchSuccessSignup,
  fetchFailureSignup,
  fetchCbosSuccess,
  fetchPartnerSuccess,
  listUpdateInsuraces,
  updateTerms,
  acceptServiceTermsSuccess,
} from './actions';
import { PROFILE } from './types';

type BulkUpdateType = {
  accepts_telemedicine?: boolean;
  accepts_in_person?: boolean;
  profile: string;
};

export const getProfileInfo = (state: StoreState) => state.profile.profile_info;

export function* workerSignUp({ payload }: { payload: ProfilePayload }) {
  try {
    yield call(signUp, payload);
    yield put(fetchSuccessSignup('Perfil criado com sucesso!'));
    setStatusForm('success');

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'gaEvent',
        eventCategory: 'CadastroCriarPerfilOnline',
        eventAction: 'AgendarConsulta-Envio',
        eventLabel: 'Sucesso',
      });
    }

    // sign-in after sign-up
    const { email, password } = payload;
    yield put(handleSubmitLogin({ email, password }));
  } catch (response) {
    let title = 'Erro ao criar perfil.';
    let body = 'Qualquer dúvida por favor envie para suporte@iclinic.com.br';
    const { data } = response;
    const errors = Object.values<string[]>(data);
    const emailExists = errors.some((error) =>
      error.some((errText) =>
        /Já existe uma conta com o e-mail./.test(errText),
      ),
    );
    if (emailExists) {
      title = 'Já existe uma conta com este email';
      body =
        'Caso tenha esquecido sua senha clique em Entrar e depois esqueci minha senha.';
    }
    yield put(
      notificationAdd({
        id: uuid(),
        kind: 'error',
        title,
        body,
        timeout: 30000,
      }),
    );
    yield put(fetchFailureSignup([], title));
    setStatusForm('failed');
  }
}

const getProfileInfoReducer = (state) => state.profile.profile_info;

export function* workerGetCurrentUser() {
  try {
    const res = yield call(getCurrentUser);
    const { data } = res;
    yield put(fetchSuccess(data));
    Storage.set('iclinic_integration_key', data.iclinic_integration_key);
    if (process.browser) {
      const { has_scheduling } = yield select(getProfileInfoReducer);
      const url = new URL(window.location.href);
      const integrated_ao = url.searchParams.get('integrated_ao');
      if (integrated_ao === 'true' && has_scheduling) {
        yield put(emitSuccess('Agendamento online criado com sucesso!'));
      }
    }
  } catch (err) {
    yield put(fetchFailure('Perfil não encontrado'));
    yield put(
      notificationAdd({
        id: uuid(),
        kind: 'error',
        title: 'Perfil não encontrado',
        body: 'Qualquer dúvida por favor envie para suporte@iclinic.com.br',
      }),
    );
  }
}

export function* workerGetBySlug({ slug }) {
  try {
    const res = yield call(getBySlug, slug);
    const { data } = yield res;
    yield put(fetchSuccess(data));
  } catch (err) {
    const profileSlugError = 'Erro ao encontrar perfil';
    yield put(emitError(err?.response, [profileSlugError]));
    yield put(fetchFailure(profileSlugError));
  }
}

export function* workerGetCbos() {
  try {
    const { data } = yield call(getSpecialties);
    yield put(fetchCbosSuccess(data));
  } catch (response) {
    yield put(emitError(response, ['Erro ao listar especialidades']));
  }
}

export function* workerGetPartner({ id }) {
  try {
    const res = yield call(getPartner, id);
    const { data } = yield res;
    yield put(fetchPartnerSuccess(data));
  } catch (response) {
    yield put(emitError(response, ['Erro ao listar parceiro']));
  }
}

export function* workerSave() {
  try {
    // Fake save
    yield put(emitSuccess('Dados salvos com sucesso'));
  } catch (response) {
    yield put(emitError(response, ['Erro ao salvar informações do perfil']));
  }
}

export function* workerBulkUpdateInsurances({ payload }: BulkUpdateType) {
  try {
    const { id } = yield select(getProfileInfo);
    const { data } = yield call(bulkUpdate, { ...payload, profile: id });
    yield put(listUpdateInsuraces(data));
  } catch (error) {
    yield put(emitError(error));
  }
}

export function* workerUpdateLocalTerms(term) {
  const { service_terms } = yield select(getProfileInfo);
  const serviceTermsObject = [...service_terms];

  const versionTerm = service_terms.findIndex(
    (version) =>
      version.version === term.version &&
      version.identifier === term.identifier,
  );
  serviceTermsObject[versionTerm].accepted = true;

  yield put(updateTerms(serviceTermsObject));
}

export function* workerAcceptServiceTerms(action) {
  const { term } = action.payload;
  try {
    const { id } = yield select(getProfileInfo);

    yield call(acceptTerms, id, {
      identifier: term.identifier,
      version: term.version,
    });

    yield put(acceptServiceTermsSuccess());

    yield call(workerUpdateLocalTerms, term);
  } catch (error) {
    yield put(emitError(error));
  }
}

export function* watchProfiles() {
  yield takeLatest(PROFILE.SAVE, workerSave);
  yield takeLatest(PROFILE.BY_ID, workerGetCurrentUser);
  yield takeLatest(PROFILE.BY_SLUG, workerGetBySlug);
  yield takeLatest(PROFILE.SIGNUP, workerSignUp);
  yield takeLatest(PROFILE.FETCH_CBOS, workerGetCbos);
  yield takeLatest(PROFILE.FETCH_PARTNER, workerGetPartner);
  yield takeLatest(PROFILE.BULK_UPDATE, workerBulkUpdateInsurances);
  yield takeLatest(PROFILE.ACCEPT_SERVICE_TERMS, workerAcceptServiceTerms);
}
