import crms from 'app/shared/constants/crms';
import { AVATAR } from 'app/state/avatar/types';
import treatments from 'app/shared/constants/treatments';

import { PROFILE } from './types';

export const profile_create = {
  cbo: null,
  partner: null,
  first_name: '',
  last_name: '',
  email: '',
  register_number: '',
  password: '',
  password_confirmation: '',
  treatment: null,
  council_name: null,
  specialties: [],
};

export const initial_state = {
  profile_info: {
    educations: [],
    addresses: [],
    services: [],
    phones: [],
    insurances: [],
    has_scheduling: false,
    iclinic_physician_id: null,
    cbo: null,
    treatment: null,
    service_terms: [],
  },
  profile_create,
  email: '',
  is_fetching: false,
  show_error: false,
  message: '',
  is_created: false,
  errors: [],
  specialties: [],
  treatments,
  crms,
  slug: null,
  rqe: null,
};

const profile = (state = initial_state, action) => {
  switch (action.type) {
    case PROFILE.BY_ID:
      return {
        ...state,
        is_fetching: true,
      };
    case PROFILE.BY_SLUG:
      return {
        ...state,
        is_fetching: true,
      };
    case PROFILE.SIGNUP:
      return {
        ...state,
        is_fetching: true,
        is_created: false,
      };
    case PROFILE.FETCH_CBOS:
      return {
        ...state,
        is_fetching: true,
      };
    case PROFILE.FETCH_PARTNER:
      return {
        ...state,
        is_fetching: true,
      };
    case PROFILE.FETCH_CBOS_SUCCESS:
      return {
        ...state,
        is_fetching: false,
        specialties: action.payload,
      };
    case PROFILE.FETCH_PARTNER_SUCCESS:
      return {
        ...state,
        is_fetching: true,
        partner: action.payload,
      };
    case PROFILE.FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        profile_info: {
          ...state.profile_info,
          services: action.payload,
        },
      };
    case PROFILE.FETCH_FAILURE:
      return {
        ...state,
        is_fetching: false,
        show_error: true,
        message: action.message,
        is_created: false,
        profile_info: {
          ...initial_state.profile_info,
        },
      };
    case PROFILE.FETCH_FAILURE_SIGNUP:
      return {
        ...state,
        is_fetching: false,
        show_error: true,
        errors: action.errors,
        message: action.message,
        is_created: false,
      };
    case PROFILE.FETCH_SUCCESS:
      return {
        ...state,
        profile_info: action.payload,
      };
    case PROFILE.FETCH_SUCCESS_SIGNUP:
      return {
        ...state,
        show_error: false,
        message: action.message,
        is_created: true,
      };
    case PROFILE.RESET:
      return {
        ...state,
        profile_create,
        is_created: true,
      };
    case PROFILE.UPDATE:
      return {
        ...state,
        profile_info: {
          ...state.profile_info,
          ...action.payload.data,
        },
      };
    case PROFILE.LIST_UPDATE:
      return {
        ...state,
        profile_info: {
          ...state.profile_info,
          [action.payload.entity]: state.profile_info[
            action.payload.entity
          ].map((item) =>
            item.id !== action.payload.data.id ? item : action.payload.data,
          ),
        },
      };
    case PROFILE.LIST_ADD:
      return {
        ...state,
        profile_info: {
          ...state.profile_info,
          [action.payload.entity]: [
            ...state.profile_info[action.payload.entity],
            action.payload.data,
          ],
        },
      };
    case PROFILE.LIST_REMOVE:
      return {
        ...state,
        profile_info: {
          ...state.profile_info,
          [action.payload.entity]: state.profile_info[
            action.payload.entity
          ].filter((item) => item.id !== action.payload.id),
        },
      };
    case AVATAR.REMOVE:
      return {
        ...state,
        profile_info: {
          ...state.profile_info,
          picture: null,
        },
      };
    case PROFILE.LIST_UPDATE_INSURANCES:
      return {
        ...state,
        profile_info: {
          ...state.profile_info,
          insurances: action.payload.insurances,
        },
      };
    case PROFILE.ACCEPT_SERVICE_TERMS:
      return {
        ...state,
        is_fetching: action.payload.is_fetching,
      };
    case PROFILE.ACCEPT_SERVICE_TERMS_SUCCESS:
      return {
        ...state,
        is_fetching: action.payload.is_fetching,
      };
    case PROFILE.UPDATE_SERVICE_TERMS:
      return {
        ...state,
        profile_info: {
          ...state.profile_info,
          service_terms: action.payload.terms,
        },
      };
    default:
      return state;
  }
};

export default profile;
