import { AVATAR } from './types';

type payloadFinishType = { src: string };
type payloadUploadType = { data: { image: { src: string } } };
type initialStateType = {
  is_uploading: boolean;
  src: string | null;
};

export const initialState: initialStateType = {
  is_uploading: false,
  src: null,
};

const avatar = (state = initialState, action: { type: string; payload?: {} }): initialStateType => {
  switch (action.type) {
    case AVATAR.UPLOAD:
      return {
        ...state,
        is_uploading: true,
        src: (action.payload as payloadUploadType).data.image.src,
      };
    case AVATAR.FINISH:
      return {
        ...state,
        is_uploading: false,
        src: (action.payload as payloadFinishType).src,
      };
    case AVATAR.REMOVE:
      return {
        ...state,
        src: null,
        is_uploading: false,
      };
    case AVATAR.ERROR:
      return {
        ...state,
        src: null,
        is_uploading: false,
      };
    default:
      return state;
  }
};

export default avatar;
