import { put, takeLatest, call, select } from 'redux-saga/effects';
import { emitError, emitSuccess } from 'app/state/status/actions';
import { StoreState } from 'app/state/rootReducer';
import {
  getByDate,
  getConfigurations,
  saveEvent,
  resendEvent,
  confirmEvent,
  getInsurancesByClinic,
  getLastSchedules,
} from 'app/services/api/schedule';
import { insuranceStatusToggle } from 'app/state/profile/utils';
import uuid from 'uuid/v4';
import {
  fetchEventsSuccess,
  fetchConfigurationsSuccess,
  fetchConfigurationsEventsError,
  fetchSaveEvent,
  fetchSaveEventSuccess,
  fetchConfirmEvent,
  fetchConfirmEventSuccess,
  fetchResendEvent,
  fetchInsurancesClinicSuccess,
  fetchEvents,
  fetchLastSchedulesSuccess,
  hideSchedule,
  closeModal,
  goToFirstStep,
  clearStepLoading,
  putDefaultClinicId,
  changeAttendanceType,
} from './actions';
import * as types from './constants';
import lastSchedulesFormat from './utils';
import { notificationAdd } from '../notification/actions';

export const getProfileInfo = (state: StoreState) => state.profile.profile_info;
export const getSchedule = (state: StoreState) => state.schedule;

export function* workerGetByDate() {
  try {
    const { start_date, end_date, clinic_id } = yield select(getSchedule);
    const { iclinic_physician_id } = yield select(getProfileInfo);
    const { data } = yield call(
      getByDate,
      start_date,
      end_date,
      clinic_id,
      iclinic_physician_id,
    );
    yield put(fetchEventsSuccess(data));
  } catch (response) {
    yield put(hideSchedule());
    yield put(emitError(response));
  }
}

export function* workerGetInsurancesByClinic() {
  try {
    const { clinic_id } = yield select(getSchedule);
    const { iclinic_physician_id } = yield select(getProfileInfo);
    const { data } = yield call(
      getInsurancesByClinic,
      clinic_id,
      iclinic_physician_id,
    );
    const status = insuranceStatusToggle(data);
    yield put(changeAttendanceType(status));
    yield put(fetchInsurancesClinicSuccess(data));
  } catch (response) {
    yield put(emitError(response));
  }
}

export function* workerGetConfigurations() {
  try {
    const { iclinic_physician_id } = yield select(getProfileInfo);
    const { data } = yield call(getConfigurations, iclinic_physician_id);
    yield put(fetchConfigurationsSuccess(data));
  } catch (response) {
    yield put(hideSchedule());
    yield put(emitError(response));
  }
}

export function* workerGetConfigurationsEvents() {
  try {
    const { start_date, end_date } = yield select(getSchedule);
    const { iclinic_physician_id } = yield select(getProfileInfo);
    const { data: configurationData } = yield call(
      getConfigurations,
      iclinic_physician_id,
    );
    yield put(fetchConfigurationsSuccess(configurationData));

    if (configurationData && configurationData.length > 0) {
      // get first clinic_id
      const { clinic_id } = configurationData[0];
      const { data: eventsData } = yield call(
        getByDate,
        start_date,
        end_date,
        clinic_id,
        iclinic_physician_id,
      );
      yield put(putDefaultClinicId(clinic_id));
      yield put(fetchEventsSuccess(eventsData));
      const { data: insurancesData } = yield call(
        getInsurancesByClinic,
        clinic_id,
        iclinic_physician_id,
      );
      yield put(fetchInsurancesClinicSuccess(insurancesData));
    } else {
      // friendly error
      yield put(
        fetchConfigurationsEventsError(
          'O perfil não possui nenhuma clínica cadastrada, cadastre-a no iClinic',
        ),
      );
      yield put(hideSchedule());
    }
  } catch (response) {
    yield put(emitError(response));
    yield put(hideSchedule());
  }
}

type FetchSaveEvent = ReturnType<typeof fetchSaveEvent>;
export function* workerSaveEvent({ payload }: FetchSaveEvent) {
  try {
    // saves patient info
    const { data } = yield call(saveEvent, payload);
    const { token } = data;
    yield put(fetchSaveEventSuccess(token));
  } catch (response) {
    yield put(emitError(response));
    yield put(clearStepLoading());
  }
}

type FetchResendEvent = ReturnType<typeof fetchResendEvent>;
export function* workerResendEvent({ payload }: FetchResendEvent) {
  try {
    // saves patient info
    const { data } = yield call(resendEvent, payload);
    const { token } = data;
    yield put(fetchSaveEventSuccess(token));
    yield put(emitSuccess('Código reenviado com sucesso'));
  } catch (response) {
    yield put(emitError(response));
    yield put(clearStepLoading());
  }
}

export function* workerReloadEvent() {
  try {
    const { start_date, end_date } = yield select(getSchedule);
    yield put(fetchEvents(start_date, end_date));
  } catch (response) {
    yield put(emitError(response));
  }
}

type FetchConfirmEvent = ReturnType<typeof fetchConfirmEvent>;
export function* workerConfirmEvent({ params }: FetchConfirmEvent) {
  try {
    const { code } = params;
    const { token } = yield select(getSchedule);
    yield call(confirmEvent, { token, code });
    yield put(fetchConfirmEventSuccess());
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'gaEvent',
        eventCategory: 'Agendamento',
        eventAction: 'AgendarConsulta-Envio',
        eventLabel: 'Sucesso',
      });
    }
    yield call(workerReloadEvent);
  } catch ({ response }) {
    if (response.status === 409) {
      yield put(
        notificationAdd({
          id: uuid(),
          kind: 'error',
          title: 'Agendamento indisponível no horário selecionado',
          body: 'Selecione outro horário na agenda',
          timeout: 30000,
        }),
      );
      yield call(workerReloadEvent);
      yield put(closeModal());
      yield put(goToFirstStep());
    } else {
      yield put(
        notificationAdd({
          id: uuid(),
          kind: 'error',
          title: 'Código incorreto',
          body: 'Verifique o código e tente novamente',
          timeout: 30000,
        }),
      );
      yield put(clearStepLoading());
    }
  }
}

export function* workerLastSchedules() {
  try {
    const { data } = yield call(getLastSchedules);
    yield put(fetchLastSchedulesSuccess(lastSchedulesFormat(data)));
  } catch (response) {
    yield put(emitError(response));
  }
}

export function* watchSchedule() {
  yield takeLatest(types.FETCH_EVENTS, workerGetByDate);
  yield takeLatest(types.FETCH_INSURANCES_CLINIC, workerGetInsurancesByClinic);
  yield takeLatest(types.FETCH_CONFIGURATIONS, workerGetConfigurations);
  yield takeLatest(
    types.FETCH_CONFIGURATIONS_EVENTS,
    workerGetConfigurationsEvents,
  );
  yield takeLatest(types.FETCH_SAVE_EVENT, workerSaveEvent);
  yield takeLatest(types.FETCH_RESEND_EVENT, workerResendEvent);
  yield takeLatest(types.FETCH_CONFIRM_EVENT, workerConfirmEvent);
  yield takeLatest(types.FETCH_LAST_SCHEDULE, workerLastSchedules);
}
