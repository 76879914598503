import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createStore, applyMiddleware } from 'redux';

import rootSaga from './rootSaga';
import rootReducer from './rootReducer';

function configureStore(preloadedState, { isServer, req = null } = {}) {
  const sagaMiddleware = createSagaMiddleware({
    emitter: (emit) => (action) => {
      if (Array.isArray(action)) {
        action.forEach(emit);
        return;
      }
      emit(action);
    },
  });
  const store = createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(
      applyMiddleware(sagaMiddleware),
    ),
  );
  if (req || !isServer) {
    store.sagaTask = sagaMiddleware.run(rootSaga);
  }
  return store;
}

export default configureStore;
