import { publicApi } from './api';

export interface AuthResponse {
  token: string;
  signup_completed: boolean;
}

export async function signIn(
  email: string,
  password: string,
): Promise<AuthResponse> {
  const result = await publicApi.post('/v2/authy/signin/', {
    email,
    password,
  });
  return result.data as AuthResponse;
}
