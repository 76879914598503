// Internal
import api from './api';

type BulkUpdateType = {
  accepts_telemedicine?: boolean;
  accepts_in_person?: boolean;
  profile: string;
};

// endpoints
export const create = (id, payload) => api.post('/insurances/', payload);
export const update = (id, payload) => api.patch(`/insurances/${id}/`, payload);
export const remove = id => api.delete(`/insurances/${id}/`);
export const bulkUpdate = (payload: BulkUpdateType) => api.patch('insurances/bulk-update/', [payload]);

export default {
  create,
  update,
  remove,
  bulkUpdate,
};
