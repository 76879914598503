import { put, takeLatest, call } from 'redux-saga/effects';
import { emitError } from 'app/state/status/actions';
import {
  fetchResultSearchSuccess,
  fetchResultSearchFailure,
  fetchPageResultFailure,
  fetchPageResultSuccess,
  fetchUserLocationSuccess,
  fetchUserLocationFailure,
} from './actions';
import { SEARCH } from './types';
import {
  getDoctors,
  getPageDoctors,
  getUserLocation,
} from '../../services/api/search';

export function* workerGetDoctors({
  autocomplete,
  insurance,
  city,
  page,
  accepts_telemedicine,
}: {
  autocomplete: string;
  insurance: string;
  city: string;
  page: number;
  accepts_telemedicine: string;
}) {
  try {
    const { data } = yield call(
      getDoctors,
      autocomplete,
      insurance,
      city,
      accepts_telemedicine,
      page,
    );
    yield put(fetchResultSearchSuccess(data));
  } catch (response) {
    yield put(emitError(response));
    yield put(fetchResultSearchFailure('Erro ao fazer a busca'));
  }
}

export function* workerGetPageDoctors({ link }) {
  try {
    const { data } = yield call(getPageDoctors, link);
    yield put(fetchPageResultSuccess(data));
  } catch (response) {
    yield put(emitError(response));
    yield put(fetchPageResultFailure('Erro ao fazer a busca'));
  }
}

export function* workerGetLocation({ location }) {
  try {
    const { latitude, longitude } = location;
    const { data } = yield call(getUserLocation, latitude, longitude);
    const { address_components } = data.results[0];
    const { long_name } = address_components[3];
    yield put(fetchUserLocationSuccess(long_name));
  } catch (response) {
    yield put(emitError(response));
    yield put(fetchUserLocationFailure('Erro ao buscar localização'));
  }
}

export function* watchSearch() {
  yield takeLatest(SEARCH.FETCH_EVENTS, workerGetDoctors);
  yield takeLatest(SEARCH.USER_LOCATION, workerGetLocation);
  yield takeLatest(SEARCH.FETCH_PAGE_RESULT, workerGetPageDoctors);
}
