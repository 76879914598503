// External
import { all, fork } from 'redux-saga/effects';
import { watchProfiles } from './profile/sagas';
import { watchAuth } from './auth/sagas';
import { watchEmitters } from './status/sagas';
import { watchShared } from './shared/sagas';
import { watchAvatar } from './avatar/sagas';
import { watchSchedule } from './schedule/sagas';
import { watchSearch } from './search/sagas';

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    fork(watchAuth),
    fork(watchProfiles),
    fork(watchEmitters),
    fork(watchAvatar),
    fork(watchShared),
    fork(watchSchedule),
    fork(watchSearch),
  ]);
}
