export default [
  { id: 1, label: 'CRAS' },
  { id: 2, label: 'CRBM' },
  { id: 3, label: 'CREFITO' },
  { id: 4, label: 'COREN' },
  { id: 5, label: 'CREF' },
  { id: 6, label: 'CRF' },
  { id: 7, label: 'CRFA' },
  { id: 8, label: 'CRM' },
  { id: 9, label: 'CRN' },
  { id: 10, label: 'CRO' },
  { id: 11, label: 'CRP' },
  { id: 12, label: 'CRT' },
  { id: 13, label: 'CRNT' },
  { id: 14, label: 'Outros' },
];
