import api from 'app/services/api/api';
import { put, takeLatest } from 'redux-saga/effects';
import { emitError, emitSuccess } from 'app/state/status/actions';
import uuid from 'uuid/v4';
import { AVATAR } from './types';
import { avatarRemove, avatarFinish, avatarError } from './actions';
import { notificationAdd } from '../notification/actions';

export function* sendFile({
  payload: { data },
}: {
  payload: {
    data: {
      image: { file: {}; src: string | ArrayBuffer | null };
      form: FormData;
    };
  };
}) {
  try {
    const response = yield api.put('/v2/profiles/me/avatar/', data.form);
    yield put(avatarFinish(response.data.picture));
    yield put(emitSuccess('Imagem enviada com sucesso!'));
  } catch (err) {
    yield put(avatarError());
    yield put(
      notificationAdd({
        id: uuid(),
        kind: 'error',
        title: 'Erro ao efetuar o upload',
        body:
          'Verifique se o arquivo é uma imagem e não excedeu o tamanho de 12 mb',
      }),
    );
  }
}

export function* deleteFile() {
  try {
    yield api.delete('/v2/profiles/me/avatar/');
    yield put(avatarRemove());
    yield put(emitSuccess('Imagem deletada com sucesso!'));
  } catch (err) {
    yield put(avatarError());
    yield put(emitError(err));
  }
}

export function* watchAvatar() {
  yield takeLatest(AVATAR.UPLOAD, sendFile);
  yield takeLatest(AVATAR.DELETE, deleteFile);
}
