import uuid from 'uuid/v4';
import Router from 'next/router';
import { takeLatest, call, put } from 'redux-saga/effects';
import { notificationAdd } from 'app/state/notification/actions';

import { ERROR, SUCCESS } from './types';

function printErrorsToList(errorsValues: object | string, messages?: string[]): string | string[] {
  if (messages && messages.length > 0) {
    return messages;
  }
  if (typeof errorsValues === 'object') {
    return Object
      .values(errorsValues)
      .reduce((errString: string[], currentError: string[]) => errString.concat(currentError));
  }
  return '';
}

export function* handleApiError(error: any, messages: string[]) {
  const { data } = error;
  const errorsList = printErrorsToList(data, messages);

  if (data.message === 'Unauthorized.') yield call(Router.push, '/login');

  yield put(notificationAdd({
    id: uuid(),
    kind: 'error',
    title: errorsList.length === 0 ? 'Ops! Um ocorreu um erro' : '',
    body: errorsList,
  }));
}

// We need to think what to do with Error - i.e: Showing a Toast with the message
export function* handleError(messages: string[]) {
  const message = (messages?.length) > 0 ? messages[0] : 'Ops! Um ocorreu um erro';
  yield put(notificationAdd({
    id: uuid(),
    kind: 'error',
    title: message,
    body: '',
  }));
}

type ClassifyError = {
  response: { data: any },
  messages: string[]
};
export function* classifyError({ response, messages }: ClassifyError) {
  if (response?.data) {
    yield call(handleApiError, response, messages);
  } else {
    yield call(handleError, messages);
  }
}

export function* classifySuccess({ payload: { success } }:{ payload: { success: string } }) {
  if (success) {
    yield put(notificationAdd({
      id: uuid(),
      kind: 'success',
      title: success,
      body: '',
    }));
  }
}

export function* watchEmitters() {
  yield takeLatest(ERROR.EMIT, classifyError);
  yield takeLatest(SUCCESS.EMIT, classifySuccess);
}
