import { LastSchedule } from './types';

const lastSchedulesFormat = (schedules: LastSchedule[]) => {
  const formatSchedyles = schedules.map((schedule) => {
    const diff = Math.abs(new Date(schedule.creation_date) - new Date());
    const minutes = Math.floor((diff / 1000) / 60);
    if (minutes >= 60) {
      const hours = Math.floor(minutes / 60);

      return {
        cbo: schedule.cbo,
        location: `${schedule.city} - ${schedule.state}`,
        time: hours > 1 ? `há ${hours} horas` : `há ${hours} hora`,
      };
    }
    return {
      cbo: schedule.cbo,
      location: `${schedule.city} - ${schedule.state}`,
      time: minutes > 1 ? `há ${minutes} minutos` : `há ${minutes} minuto`,
    };
  });
  return formatSchedyles || [];
};

export default lastSchedulesFormat;
