// External
import { Reducer } from 'redux';

// Internal
import { ActionType } from '@types';
import * as types from './constants';
import * as actions from './actions';
import { AuthState } from './types';

export const initialState: AuthState = {
  loading: false,
  errors: null,
  pendingConfirmation: false,
};

type AuthActions = ActionType<typeof actions>;

const authReducer: Reducer<AuthState, AuthActions> = (
  state = initialState,
  action: AuthActions,
): AuthState => {
  if (action.type === types.SET_PENDING_CONFIRMATION) {
    return { ...state, pendingConfirmation: true };
  }
  return state;
};

export default authReducer;
