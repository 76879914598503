// Internal
import api from './api';

// endpoints
export const create = (id, payload) =>
  api.post('/v2/profiles/me/addresses/', payload);
export const update = (id, payload) =>
  api.patch(`/v2/profiles/me/addresses/${id}/`, payload);
export const remove = (id) => api.delete(`/v2/profiles/me/addresses/${id}/`);
export const getById = (id) => api.post(`/v2/profiles/me/addresses/${id}/`);

export default {
  create,
  update,
  remove,
  getById,
};
