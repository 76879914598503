// Internal
import { appTypes } from 'app/shared/utils/actions';

const shared = appTypes.defineAction('shared');

// eslint-disable-next-line import/prefer-default-export
export const SHARED = shared.defineAction('SHARED',
  [
    'API_FETCH',
  ],
);
