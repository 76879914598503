import { NOTIFICATION } from './types';

const initialState = {
  messages: [],
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION.ADD:
      return {
        ...state,
        messages: [...state.messages, action.payload],
        timeout: action.payload.timeout,
      };
    case NOTIFICATION.REMOVE:
      return {
        ...state,
        messages: state.messages.filter(
          (message) => message.id !== action.payload,
        ),
      };
    default:
      return state;
  }
};

export default notification;
