import Router from 'next/router';
import Storage from 'app/shared/utils/Storage';
import { put, takeLatest, call } from 'redux-saga/effects';
import { emitError, emitSuccess } from 'app/state/status/actions';
import { setStatusForm } from 'shared/utils/validation';
import { signIn as signInV2, AuthResponse } from 'app/services/api/auth';
import { signIn } from 'app/services/api/auth_legacy';
import Auth from '@aws-amplify/auth';
import logout from 'app/state/auth/utils';
import uuid from 'uuid/v4';
import {
  handleSubmitLogin,
  handleSubmitPasswordChange,
  handleSubmitPasswordReset,
  resendVerification,
} from './actions';
import * as types from './constants';
import { notificationAdd } from '../notification/actions';

Auth.configure({
  mandatorySignIn: true,
  region: process.env.COGNITO_AWS_REGION,
  userPoolId: process.env.COGNITO_USER_POOL,
  userPoolWebClientId: process.env.COGNITO_AUDIENCE,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
});

function* workerFailureAuth(error: any) {
  setStatusForm('failed');
  yield put(emitError(error, ['E-mail ou senha estão incorretos']));
}

function* workerSuccessAuth() {
  const { integrate } = Router.query;
  const param = integrate ? '?integrate=true' : '';
  const url = `/editar-perfil${param}`;
  yield call(Router.push, url);
  setStatusForm('success');
}

function redirectToFinishProfile() {
  window.location.href = '/new/complete-seu-perfil';
}

export function* workerSignInLegacy(email: string, password: string) {
  try {
    const {
      data: { key, profile_id },
    } = yield call(signIn, email, password);
    Storage.set('profile_id', profile_id);
    Storage.set('authorization', `Token ${key}`);
    yield call(workerSuccessAuth);
  } catch (error) {
    yield call(workerFailureAuth, error);
  }
}

type SignIn = ReturnType<typeof handleSubmitLogin>;

export function* workerSignIn({ payload: { email, password } }: SignIn) {
  try {
    const result: AuthResponse = yield call(signInV2, email, password);
    Storage.set('authorization', `Bearer ${result.token}`);
    if (!result.signup_completed) {
      redirectToFinishProfile();
    } else {
      yield call(workerSuccessAuth);
    }
  } catch (error) {
    // fallback temporário p/ migração Cognito
    yield call(workerSignInLegacy, email, password);
  }
}

type RequestPasswordChange = ReturnType<typeof handleSubmitPasswordChange>;
export function* workerRequestPasswordChange({
  payload: { email },
}: RequestPasswordChange) {
  try {
    yield call([Auth, 'forgotPassword'], email);
    setStatusForm('success');
    yield put(emitSuccess('Enviado com sucesso'));
    yield call(Router.push, '/');
  } catch (response) {
    const { code } = response;
    if (code === 'UserNotFoundException') {
      yield put(
        notificationAdd({
          id: uuid(),
          kind: 'error',
          title: 'Não existe um cadastro com esse e-mail',
          body: 'Crie agora um perfil com esse email clicando em Criar perfil médico',
          timeout: 30000,
        }),
      );
    } else {
      setStatusForm('failed');
      yield put(
        emitError(response, [
          'Ocorreu um erro ao enviar o e-mail, tente novamente.',
        ]),
      );
    }
  }
}

type RequestPasswordReset = ReturnType<typeof handleSubmitPasswordReset>;
export function* workerRequestPasswordReset({ payload }: RequestPasswordReset) {
  try {
    const { username, token: code, password: new_password } = payload;
    yield call([Auth, 'forgotPasswordSubmit'], username, code, new_password);
    yield call(Router.push, '/login');
    yield put(emitSuccess('Senha alterada com sucesso'));
  } catch (err) {
    if (err?.code === 'ExpiredCodeException') {
      yield put(
        emitError(err, [
          'Seu código expirou, solicite o reenvio de senha novamente',
        ]),
      );
      yield call(Router.push, '/recuperar-senha');
    } else {
      yield put(
        emitError(err, [
          'Ocorreu um erro ao alterar sua senha, tente novamente',
        ]),
      );
    }
  }
}

export function* workerLogout() {
  try {
    yield call(logout);
  } catch (error) {
    yield put(emitError(error, ['Erro ao efetuar logout']));
  }
}

type ResendVerification = ReturnType<typeof resendVerification>;
export function* workerResendVerification({ payload }: ResendVerification) {
  try {
    const { username } = payload;
    yield call([Auth, 'resendSignUp'], username);
    yield put(emitSuccess('Link de verificação enviado para seu e-mail'));
  } catch (error) {
    yield put(
      emitError(error, [
        'Ocorreu um erro ao reenviar seu e-mail de confirmação, por favor, tente novamente.',
      ]),
    );
  }
}

export function* watchAuth() {
  yield takeLatest(types.SUBMIT_LOGIN, workerSignIn);
  yield takeLatest(types.SUBMIT_LOGOUT, workerLogout);
  yield takeLatest(types.SUBMIT_PASSWORD_CHANGE, workerRequestPasswordChange);
  yield takeLatest(types.SUBMIT_PASSWORD_RESET, workerRequestPasswordReset);
  yield takeLatest(types.RESEND_VERIFICATION, workerResendVerification);
}
