import { put, takeLatest, call } from 'redux-saga/effects';
import phones from 'app/services/api/phones';
import services from 'app/services/api/services';
import insurances from 'app/services/api/insurances';
import { profileUpdate, listUpdate, listAdd, listRemove } from 'app/state/profile/actions';
import educations from 'app/services/api/educations';
import addresses from 'app/services/api/addresses';
import profiles from 'app/services/api/profile';
import { SHARED } from './types';
import { emitError, emitSuccess } from '../status/actions';

const apis = {
  phones: {
    PATCH: phones.update,
    DELETE: phones.remove,
    POST: phones.create,
  },
  services: {
    PATCH: services.update,
    DELETE: services.remove,
    POST: services.create,
    GET: services.get,
  },
  insurances: {
    PATCH: insurances.update,
    DELETE: insurances.remove,
    POST: insurances.create,
  },
  educations: {
    PATCH: educations.update,
    DELETE: educations.remove,
    POST: educations.create,
  },
  addresses: {
    PATCH: addresses.update,
    DELETE: addresses.remove,
    POST: addresses.create,
  },
  profiles: {
    PATCH: profiles.update,
  },
};

const actions = {
  POST: (kind, data) => listAdd(kind, data),
  PATCH: (kind, data) => listUpdate(kind, data),
  DELETE: (kind, data) => listRemove(kind, data),
};

export function* workerApiFetch({ id, kind, verb, payload = {}, onSuccess = (() => {}) }) {
  try {
    const api = apis[kind];
    const { data } = yield call(api[verb], id, payload);

    if (kind !== 'profiles') {
      yield put(actions[verb](kind, verb !== 'DELETE' ? data : id));
    } else {
      yield put(profileUpdate(data));
    }
    yield put(emitSuccess('Seus dados foram atualizados'));
    yield call(onSuccess);
  } catch (error) {
    yield put(emitError(error));
  }
}

export function* watchShared() {
  yield takeLatest(SHARED.API_FETCH, workerApiFetch);
}
