// Internal
import api from './api';

// endpoints
export const create = (id, payload) =>
  api.post('/v2/profiles/me/services/', payload);
export const update = (id, payload) =>
  api.patch(`/v2/profiles/me/services/${id}/`, payload);
export const remove = (id) => api.delete(`/v2/profiles/me/services/${id}/`);
export const get = () => api.post('/v2/profiles/me/services/');

export default {
  create,
  update,
  remove,
  get,
};
