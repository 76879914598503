import { ActionType } from '@types';
import * as types from './constants';
import * as actions from './actions';
import { ScheduleState } from './types';

export const initialState: ScheduleState = {
  events: [],
  clinics: [],
  clinic_id: null,
  insurances: [],
  start_date: '',
  end_date: '',
  is_fetching: true,
  is_fetching_configurations: false,
  is_fetching_steps: false,
  error: '',
  hide_schedule: false,
  patient: null,
  token: null,
  step: 1,
  isModalVisible: false,
  lastSchedule: [],
  attendancePerson: true,
};

type ScheduleActions = ActionType<typeof actions>;

const schedule = (
  state = initialState,
  action: ScheduleActions,
): ScheduleState => {
  switch (action.type) {
    case types.TOGGLE_MODAL_VISIBLE:
      return {
        ...state,
        isModalVisible: !state.isModalVisible,
      };
    case types.OPEN_MODAL:
      return {
        ...state,
        isModalVisible: true,
      };
    case types.CLOSE_MODAL:
      return {
        ...state,
        isModalVisible: false,
      };
    case types.FETCH_CONFIGURATIONS_EVENTS:
    case types.FETCH_EVENTS:
      return {
        ...state,
        is_fetching: true,
        start_date: action.payload.start_date,
        end_date: action.payload.end_date,
      };
    case types.FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
        is_fetching: false,
        hide_schedule: false,
      };
    case types.FETCH_CONFIGURATIONS:
      return {
        ...state,
        is_fetching_configurations: true,
      };
    case types.CLEAR_STEP_LOADING:
      return {
        ...state,
        is_fetching_steps: false,
      };
    case types.HIDE_SCHEDULE:
      return {
        ...state,
        hide_schedule: true,
      };
    case types.PUT_DEFAULT_CLINIC_ID: {
      return {
        ...state,
        clinic_id: action.payload.clinic_id,
      };
    }
    case types.CHANGE_CLINIC_ID:
      return {
        ...state,
        clinic_id: action.payload.clinic_id,
      };
    case types.CHANGE_ATTENDANCE:
      return {
        ...state,
        attendancePerson: action.payload.attendancePerson,
      };
    case types.FETCH_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        is_fetching_configurations: false,
        clinics: action.payload,
      };
    case types.FETCH_CONFIGURATIONS_EVENTS_ERROR:
      return {
        ...state,
        error: action.payload.message,
        clinics: [],
        events: [],
      };

    // schedule form steps
    case types.FETCH_INSURANCES_CLINIC_SUCCESS:
      return {
        ...state,
        insurances: action.payload,
      };
    case types.FETCH_SAVE_EVENT:
      return {
        ...state,
        patient: action.payload,
        is_fetching_steps: true,
        step: 1,
      };
    case types.FETCH_RESEND_EVENT:
      return {
        ...state,
        is_fetching_steps: true,
      };
    case types.FETCH_SAVE_EVENT_SUCCESS:
      return {
        ...state,
        is_fetching_steps: false,
        patient: null,
        token: action.payload.token,
        step: 2,
      };
    case types.FETCH_CONFIRM_EVENT:
      return {
        ...state,
        is_fetching_steps: true,
      };
    case types.FETCH_CONFIRM_EVENT_SUCCESS:
      return {
        ...state,
        is_fetching_steps: false,
        patient: null,
        token: null,
        step: 3,
      };
    case types.GO_TO_FIRST_STEP:
      return {
        ...state,
        is_fetching_steps: false,
        step: 1,
      };
    case types.FETCH_LAST_SCHEDULE_SUCCESS:
      return {
        ...state,
        lastSchedule: action.payload,
      };
    default:
      return state;
  }
};

export default schedule;
