import { ERROR, SUCCESS } from './types';

const emitError = (response, messages) => ({
  type: ERROR.EMIT,
  response,
  messages,
});

const emitSuccess = success => ({
  type: SUCCESS.EMIT,
  payload: { success },
});

export { emitError, emitSuccess };
