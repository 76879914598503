// External
import React from 'react';
import * as Sentry from '@sentry/browser';
import Router from 'next/router';
import App from 'next/app';
import withRedux from 'next-redux-wrapper';
import withReduxSaga from 'next-redux-saga';
import { Provider } from 'react-redux';

// Internal
import createStore from 'app/state/configureStore';
import { init as initializeGA, pageView } from 'app/shared/utils/GA';
import sentryConfig from 'app/shared/constants/sentryConfig';
import { Notification } from 'app/components';

import 'styles/bootstrap.scss';
import 'styles/style.scss';
import Head from 'next/head';
import uuid from 'uuid/v4';

const dsn = process.env.SENTRY_PUBLIC_DSN;
const USER_COOKIE_NAME = '@ac-sid';
const ORIGIN_COOKIE_NAME = '@ac-origin';
const THREE_YEARS_IN_SECONDS = 94608000;
const TEN_MINUTES_IN_SECONDS = 600;

Sentry.init({
  dsn,
  release: process.env.SENTRY_RELEASE,
  environment: process.env.NODE_ENV,
  integrations: [new Sentry.Integrations.InboundFilters(sentryConfig)],
});

class MktApp extends App {
  createCookie = (name: string, value: string, expire: number) => {
    const date = new Date();
    date.setTime(date.getTime() + expire * 1000);
    const expires = `; expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}${expires}; path=/`;
  };

  getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  handlerUserCookie = () => {
    if (!this.getCookie(USER_COOKIE_NAME)) {
      this.createCookie(USER_COOKIE_NAME, uuid(), THREE_YEARS_IN_SECONDS);
    }
  };

  handlerOriginCookie = () => {
    const windowUrl =
      window.location.search.indexOf('origin') > 0
        ? window.location.search
        : window.location.href;
    const params = new URLSearchParams(windowUrl);
    if (params.has('origin')) {
      this.createCookie(
        ORIGIN_COOKIE_NAME,
        params.get('origin'),
        TEN_MINUTES_IN_SECONDS,
      );
    }
  };

  componentDidMount() {
    this.handlerUserCookie();
    this.handlerOriginCookie();
    initializeGA(process.env.GOOGLE_ANALYTICS_KEY);
    Router.onRouteChangeComplete = (url) => pageView(url);
  }

  componentDidCatch(error, errorInfo) {
    // This is needed to render errors correctly in development / production
    Sentry.captureException(error, { extra: errorInfo });
    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps, store } = this.props;

    return (
      <Provider store={store}>
        <div>
          <Notification />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Head>
            <meta
              name="viewport"
              content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"
            />
          </Head>
          <Component {...pageProps} />
        </div>
      </Provider>
    );
  }
}

export default withRedux(createStore)(withReduxSaga(MktApp));
