import { SEARCH } from './types';

const initialState = () => ({
  doctors: [],
  message: '',
  dataSearch: {},
  userLocation: '',
  is_fetching: false,
});

const search = (reduxState, action) => {
  const state = reduxState || initialState();
  switch (action.type) {
    case SEARCH.DATA_SEARCH:
      return {
        ...state,
        dataSearch: action.payload,
      };
    case SEARCH.USER_LOCATION_SUCCESS:
      return {
        ...state,
        userLocation: action.payload,
      };
    case SEARCH.FETCH_EVENTS:
    case SEARCH.FETCH_PAGE_RESULT:
      return {
        ...state,
        is_fetching: true,
      };
    case SEARCH.FETCH_EVENTS_SUCCESS:
    case SEARCH.FETCH_PAGE_RESULT_SUCCESS:
      return {
        ...state,
        is_fetching: false,
        doctors: action.payload,
      };
    case SEARCH.FETCH_EVENTS_FAILURE:
    case SEARCH.FETCH_PAGE_RESULT_FAILURE:
      return {
        ...state,
        is_fetching: false,
        message: action.message,
      };
    default:
      return state;
  }
};

export default search;
