// Internal
import { publicApi } from './api';

// endpoints
export const getByDate = (
  startDate,
  endDate,
  clinicId,
  physicianId,
) => publicApi.get(`/schedule/${clinicId}/${physicianId}/free-slots/?start_date=${startDate}&end_date=${endDate}`);

export const getConfigurations = (physicianId) => publicApi.get(`/schedule/${physicianId}/`);

export const saveEvent = (payload) => publicApi.post('/schedule/sms/', payload);
export const resendEvent = (payload) => publicApi.post('/schedule/sms/', payload);
export const confirmEvent = (params) => publicApi.get(`/schedule/sms/confirm/${params.token}/${params.code}/`);
export const getInsurancesByClinic = (clinicId, physicianId) => publicApi.get(`/schedule/${clinicId}/${physicianId}/insurances/`);
export const getLastSchedules = () => publicApi.get('/schedule/last-schedules');

export default {
  getByDate,
  getInsurancesByClinic,
};
