// External
import { combineReducers } from 'redux';

// Internal
import { ScheduleState } from 'app/state/schedule/types';
import { AuthState } from './auth/types';

import profile from './profile/reducers';
import notification from './notification/reducers';
import avatar from './avatar/reducers';
import schedule from './schedule/reducers';
import search from './search/reducers';
import auth from './auth/reducers';

export const initialState = {};

export type StoreState = {
  schedule: ScheduleState,
  auth: AuthState,
};

const rootReducer = combineReducers({
  auth,
  profile,
  notification,
  avatar,
  schedule,
  search,
});

export default rootReducer;
